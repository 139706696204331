import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "./css/privacy.styl"

const PrivacyPolicy = ({ location }) => {

    const intl = useIntl()
    const language = intl.locale

    return (
        <Layout location={location} >
            <SEO
                location={location}
                lang={language}
                title={language !== "ja" ? "Privacy" : "プライバシーポリシー"}
                keywords={[`Privacy`, `プライバシーポリシー`]}
            />
            <section className="privacy component">
                <h1>{language === 'ja' ? 'プライバシーポリシー' : 'Privacy Policy'}</h1>
                <div className="jp">
                    <p>当プロジェクトは、お客様の個人情報保護の重要性について認識し、個人情報の保護に関する法律（以下「個人情報保護法」といいます。）を遵守すると共に、以下のプライバシーポリシー（以下「本プライバシーポリシー」といいます。）に従い、適切な取扱い及び保護に努めます。</p>
                    <p>
                        <strong>1. 個人情報の定義</strong><br />
                        本プライバシーポリシーにおいて、個人情報とは、個人情報保護法第2条第1項により定義された個人情報、すなわち、生存する個人に関する情報であって、当該情報に含まれる氏名、生年月日その他の記述等により特定の個人を識別することができるもの（他の情報と容易に照合することができ、それにより特定の個人を識別することができることとなるものを含みます。）、もしくは個人識別符号が含まれる情報を意味するものとします。</p>
                    <p>
                        <strong>2. 個人情報の利用目的</strong><br />
                        当プロジェクトは、お客様の個人情報を、以下の目的で利用致します。<br />
                        （１） 当プロジェクトのサービスの提供のため<br />
                        （２） 当プロジェクトのサービスに関するご案内、お問い合わせ等への対応のため<br />
                        （３） 当プロジェクトの商品、サービス等のご案内のため<br />
                        （４） 当プロジェクトのサービスに関する当プロジェクトの規約、ポリシー等（以下「規約等」といいます。）に違反する行為に対する対応のため<br />
                        （５） 当プロジェクトのサービスに関する規約等の変更などを通知するため<br />
                        （６） 当プロジェクトのサービスの改善、新サービスの開発等に役立てるため<br />
                        （７） その他、上記利用目的に付随する目的のため
                    </p>
                    <p>
                        <strong>3. 個人情報利用目的の変更</strong><br />
                        当プロジェクトは、個人情報の利用目的を、関連性を有すると合理的に認められる範囲内において変更することがあり、変更した場合にはお客様に通知又は公表します。
                    </p>
                    <p>
                        <strong>4. 個人情報利用の制限</strong><br />
                        当プロジェクトは、個人情報保護法その他の法令により許容される場合を除き、お客様の同意を得ず、利用目的の達成に必要な範囲を超えて個人情報を取り扱いません。但し、次の場合はこの限りではありません。<br />
                        （１） 法令に基づく場合<br />
                        （２） 人の生命、身体又は財産の保護のために必要がある場合であって、お客様の同意を得ることが困難であるとき<br />
                        （３） 公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、お客様の同意を得ることが困難であるとき
                    </p>
                    <p>
                        <strong>5. 個人情報の適正な取得</strong><br />
                        当プロジェクトは、適正に個人情報を取得し、偽りその他不正の手段により取得しません。

                    </p>
                    <p>
                        <strong>6. 個人情報の安全管理</strong><br />
                        当プロジェクトは、個人情報の取扱いの全部又は一部を委託する場合は、委託先において個人情報の安全管理が図られるよう、必要かつ適切な監督を行います。
                    </p>
                    <p>
                        <strong>7. 第三者提供</strong><br />
                        当プロジェクトは、個人情報保護法その他の法令に基づき開示が認められる場合を除くほか、あらかじめお客様の同意を得ないで、個人情報を第三者に提供しません。但し、次に掲げる場合は上記に定める第三者への提供には該当しません。<br />
                        （１） 当プロジェクトが利用目的の達成に必要な範囲内において個人情報の取扱いの全部又は一部を委託することに伴って個人情報を提供する場合<br />
                        （２） 合併その他の事由による事業の承継に伴って個人情報が提供される場合<br />
                        （３） 個人情報保護法の定めに基づき共同利用する場合
                    </p>
                    <p>
                        <strong>8. 個人情報の開示</strong><br />
                        当プロジェクトは、お客様から、個人情報保護法の定めに基づき個人情報の開示を求められたときは、お客様ご本人からのご請求であることを確認の上で、お客様に対し、遅滞なく開示を行います（当該個人情報が存在しないときにはその旨を通知いたします。）。但し、個人情報保護法その他の法令により、当プロジェクトが開示の義務を負わない場合は、この限りではありません。
                    </p>
                    <p>
                        <strong>9. 個人情報の訂正等</strong><br />
                        当プロジェクトは、お客様から、個人情報が真実でないという理由によって、個人情報保護法の定めに基づきその内容の訂正、追加又は削除（以下「訂正等」といいます。）を求められた場合には、お客様ご本人からのご請求であることを確認の上で、利用目的の達成に必要な範囲内において、遅滞なく必要な調査を行い、その結果に基づき、個人情報の内容の訂正等を行い、その旨をお客様に通知します（訂正等を行わない旨の決定をしたときは、お客様に対しその旨を通知いたします。）。但し、個人情報保護法その他の法令により、当プロジェクトが訂正等の義務を負わない場合は、この限りではありません。
                    </p>
                    <p>
                        <strong>10. 個人情報の利用停止等</strong><br />
                        当プロジェクトは、お客様から、お客様の個人情報が、あらかじめ公表された利用目的の範囲を超えて取り扱われているという理由又は偽りその他不正の手段により取得されたものであるという理由により、個人情報保護法の定めに基づきその利用の停止又は消去（以下「利用停止等」といいます。）を求められた場合において、そのご請求に理由があることが判明した場合には、お客様ご本人からのご請求であることを確認の上で、遅滞なく個人情報の利用停止等を行い、その旨をお客様に通知します。但し、個人情報保護法その他の法令により、当プロジェクトが利用停止等の義務を負わない場合は、この限りではありません。
                    </p>
                    <p>
                        <strong>11. Cookie（クッキー）その他の技術の利用</strong><br />
                        当プロジェクトのサービスは、Cookie及びこれに類する技術を利用することがあります。これらの技術は、当プロジェクトによる当プロジェクトのサービスの利用状況等の把握に役立ち、サービス向上に資するものです。Cookieを無効化されたいユーザーは、ウェブブラウザの設定を変更することによりCookieを無効化することができます。但し、Cookieを無効化すると、当プロジェクトのサービスの一部の機能をご利用いただけなくなる場合があります。
                    </p>
                    <p>
                        <strong>12. お問い合わせ</strong><br />
                        開示等のお申出、ご意見、ご質問、苦情のお申出その他個人情報の取扱いに関するお問い合わせは、当プロジェクトの「特定商取引法に基づく表記」内にある連絡先へご連絡いただくか、プロジェクトウェブページ内のお問い合わせフォームよりお問い合わせください。
                    </p>
                    <p>
                        <strong>13. 継続的改善</strong><br />
                        当プロジェクトは、個人情報の取扱いに関する運用状況を適宜見直し、継続的な改善に努めるものとし、必要に応じて、本プライバシーポリシーを変更することがあります。

                    </p>
                    <p>
                        <strong>14. 製本業務委託</strong><br />
                        当プロジェクトでは、製本直送.com（以下、製本直送）に製本を委託し、本の製造および配送を行っております。製本直送のプライバシーポリシーは次のリンク先で確認できます。<br />
                        <a href="https://www.seichoku.com/user_data/kojin.php" target="_blank">製本直送 プライバシーポリシー</a>
                    </p>

                </div>
                {/* {language === 'en' && <div className="en">
                    <p>
                        <strong>1. Autoradiograph App will never use any privacy information like below.</strong>
                    </p>
                    <p>
                        Location information<br />
                        Image or any other media file storage<br />
                        Camera<br />
                        Advertising ID<br />
                        Device information
                    </p>
                    <p>
                        <strong>2. Copyright of all Sentences/Images/Movies in this App</strong>
                    </p>
                    <p>
                        All autoradiographs, images, and videos in this app are the property of Masamichi Kagaya and Satoshi Mori. Reproduction (including but not limited to screenshots), use, and distribution of any of the content contained this app is strictly prohibited without the express written consent of Masamichi Kagaya and Satoshi Mori.
                    </p>
                </div>} */}
            </section>
        </Layout >
    )
}

export default PrivacyPolicy

